@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"), url(./fonts/OpenSans-Regular.ttf);
}

@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  src: local("Open Sans"), url(./fonts/OpenSans-SemiBold.ttf);
}

@font-face {
  font-family: "Open Sans";
  font-weight: 700;
  src: local("Open Sans"), url(./fonts/OpenSans-Bold.ttf);
}

@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  src: local("Montserrat"), url(./fonts/Montserrat-Bold.ttf);
}
