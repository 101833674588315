/***** BACKGROUND COLOR FIX FOR IE *****/

.background-white-ie-fix {
  background: white;
}

/***** MOBILE FONT STYLES *****/

.main-heading-font {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.main-subheading-font {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: rgba(75, 85, 99, 0.8);
}

.body-heading-font {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.body-subheading-font {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: rgba(75, 85, 99, 0.8);
}

.form-subheading-font {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 12px;
}

.body-input-font {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: rgba(31, 41, 55, 0.8);
}

.body-font {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
}

.body-error {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: red;
}

.progress-bar-font {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 700;
}

/***** DESKTOP FONT STYLES *****/

@media (min-width: 1000px) {
  .main-heading-font {
    font-size: 28px;
  }
  .main-subheading-font {
    font-size: 20px;
  }
  .body-heading-font {
    font-size: 20px;
  }
  .body-subheading-font {
    font-size: 14px;
  }
  .form-subheading-font {
    font-size: 14px;
  }
  .body-input-font {
    font-size: 14px;
  }
  .body-font {
    font-size: 14px;
  }
  .body-error {
    font-size: 14px;
  }
  .progress-bar-font {
    font-size: 14px;
  }
}

/***** HIDE ARROWS ON NUMBER INPUTS *****/

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/***** ANONYMOUS *****/

button:disabled,
button[disabled] {
  cursor: not-allowed;
}
